<template>
  <v-container style="margin: 0 50px">
    <v-row style="margin-top: 3vh" justify="center">
      <v-col cols="12 my-0 py-0">
        <app-logo></app-logo>
      </v-col>
      <v-col cols="12" md="6">
        <div class="border-bottom">Konvey web</div>
      </v-col>
      <v-col cols="12" md="6">
        <div class="border-bottom">Konvey Extension</div>
      </v-col>
      <v-col cols="12">
        <h2>Start creating now</h2>
        <p>full feature tools for Web or Chrome</p>
      </v-col>
      <v-col cols="12" md="6" style="margin-bottom: 50px">
        <v-row>
          <v-col cols="12" md="6">
            <v-img
              :src="require('@/assets/onboarding/konvey.png')"
              height="40"
              width="40"
              style="margin: 0 auto"
              class="mb-2"
            ></v-img>
            <h3 class="mb-2">Konvey Web App</h3>
            <v-btn
              class="primary text-capitalize"
              @click="skipExtensionInstall"
            >
              Go to Dashboard
            </v-btn>
            <div class="mt-3 text-left ml-6">
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Screen recording, camera, audio</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Video request, instant notifications</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Share videos with a link, embed code</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Video option: email capture and CTA</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Team collaboration</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Custom branding and CNAME</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Video management, storage and download</small>
              </small>
            </div>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              :src="require('@/assets/onboarding/chrome.png')"
              height="40"
              width="40"
              style="margin: 0 auto"
              class="mb-2"
            ></v-img>
            <h3 class="mb-2">Konvey Web App</h3>
            <a
              href="https://chromewebstore.google.com/detail/konvey-app/kamphplkepphaijkgddddglpgmnfjllc"
              target="_blank"
              @click="installedExtension"
            >
              <v-btn class="primary text-capitalize">
                <v-icon size="15px" class="mr-2">mdi-puzzle</v-icon>
                install extension
              </v-btn>
            </a>
            <div class="mt-3 text-left ml-6">
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Screen recording, camera, audio</small>
              </small>
              <small class="d-block">
                <v-icon
                  class="mt-n1 mr-2"
                  style="font-size: 15px; -webkit-text-stroke: 2px #5968fa"
                  color="primary"
                >
                  mdi-check
                </v-icon>
                <small>Video request, instant notifications</small>
              </small>
            </div>
          </v-col>
        </v-row>
      </v-col>
      <div style="position: absolute; bottom: 5px; left: 0; right: 0">
        <div class="text-capitalize">
          Konvey, Connecting Hearts, Empowering Teams!
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import AppLogo from "../../components/Logo.vue";
export default {
  name: "Onboardning",
  components: {
    AppLogo,
  },
  data() {
    return {};
  },
  methods: {
    installedExtension() {
      window.localStorage.setItem("extension", true);
      setTimeout(async () => {
        await this.$router.replace({ name: "Dashboard" });
      }, 1000);
    },
    skipExtensionInstall() {
      window.localStorage.setItem("skip_extension", true);
      this.$router.replace({ name: "Dashboard" });
    },
  },
};
</script>

<style scoped>
.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
}
</style>
